import { NgModule, PipeTransform, Type } from '@angular/core';

import {
  ClickStopPropagationDirective, DisableControlDirective, LazyScrollDirective,
  NavigateBackDirective, ScoringColorDirective, StringToNumberDirective
} from './directives';
import {
  ActivityBaseIconPipe, AgeDifferencePipe, AgeFromNumberPipe,
  AgeObjectPipe, AgePipe, AgeResultPipe, AgeResultTextClassPipe, BytesToKilobytesPipe,
  CappedNumberPipe, ComputerScorePipe, ConvertDatePipe, DateFromDateStructPipe,
  DateFromOffsetPipe, DateOnlyPipe, DateTimePipe, DayOfWeekPipe,
  DayPipe, DecimalSwapPipe, DisplayHolidayTypePipe, DueFromOffsetPipe, DurationPipe, EndsPipe,
  GradeDifferencePipe, GradeLevelPipe, HomeWorkDueInDatePipe, HomeWorkDueInTimePipe,
  IndeterminateBooleanPipe, IsPassedPipe, LinkifyPipe, ManualScorePipe, MonthPipe, NamePipe,
  NegativeValuePipe, NoteCategoryIconPipe, NoteClassPipe, NoteIconPipe, NoteTextPipe, NumericEnumToArrayPipe, OrdinalNumberPipe, OverduePipe,
  PercentagePipe, PercentageResultAlertClassPipe,
  PercentageResultAlertOpenClassPipe, PercentageResultPipe, PercentageResultTextClassPipe,
  SafePipe, SameDayPipe, StartsInFromOffsetPipe, StartsInPipe, StartsPipe,
  SubjectIconPipe, SubjectImagePipe, TimeFromOffsetPipe, TimeOfDayPipe, TimePipe,
  UsersTimeZonePipe, WarningMaxTimePipe, WeekdayPipe
} from './pipes';

const sharedDirectives: Type<any>[] = [
  ClickStopPropagationDirective,
  DisableControlDirective,
  LazyScrollDirective,
  NavigateBackDirective,
  ScoringColorDirective,
  StringToNumberDirective
];

const sharedPipes: Type<PipeTransform>[] = [
  ActivityBaseIconPipe,
  AgeDifferencePipe,
  AgeFromNumberPipe,
  AgeObjectPipe,
  AgePipe,
  AgeResultPipe,
  AgeResultTextClassPipe,
  BytesToKilobytesPipe,
  CappedNumberPipe,
  ComputerScorePipe,
  ConvertDatePipe,
  DateFromDateStructPipe,
  DateFromOffsetPipe,
  DateOnlyPipe,
  DateTimePipe,
  DayOfWeekPipe,
  DayPipe,
  DecimalSwapPipe,
  DisplayHolidayTypePipe,
  DueFromOffsetPipe,
  DurationPipe,
  EndsPipe,
  GradeDifferencePipe,
  GradeLevelPipe,
  HomeWorkDueInDatePipe,
  HomeWorkDueInTimePipe,
  IndeterminateBooleanPipe,
  IsPassedPipe,
  LinkifyPipe,
  ManualScorePipe,
  MonthPipe,
  NamePipe,
  NegativeValuePipe,
  NoteCategoryIconPipe,
  NoteClassPipe,
  NoteIconPipe,
  NoteTextPipe,
  NumericEnumToArrayPipe,
  OrdinalNumberPipe,
  OverduePipe,
  PercentagePipe,
  PercentageResultAlertClassPipe,
  PercentageResultAlertOpenClassPipe,
  PercentageResultPipe,
  PercentageResultTextClassPipe,
  SafePipe,
  SameDayPipe,
  StartsInFromOffsetPipe,
  StartsInPipe,
  StartsPipe,
  SubjectIconPipe,
  SubjectImagePipe,
  TimeFromOffsetPipe,
  TimeOfDayPipe,
  TimePipe,
  UsersTimeZonePipe,
  WarningMaxTimePipe,
  WeekdayPipe
];

@NgModule({
  declarations: [
    ...sharedDirectives,
    ...sharedPipes
  ],
  exports: [
    ...sharedDirectives,
    ...sharedPipes
  ]
})
export class PipesDirectivesLibModule { }
