import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { DateStruct } from 'moment-extensions-lib';
import { RegionId } from 'questions-lib';
import { Subscription } from 'rxjs';
import { Gender, Student } from 'ui-common-lib';

import { StudentUpdateStatus } from '../models';
import { StudentService } from '../services';

@Component({
  selector: 'kip-student-edit-base',
  templateUrl: './student-edit-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentEditBaseComponent implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #studentId: number | undefined;
  #student: Student | undefined;
  #subscriptions: Subscription[] = [];

  readonly minDateOfBirth: DateStruct = { year: 0, month: 1, day: 1 };
  readonly maxDateOfBirth: DateStruct = { year: 9999, month: 1, day: 1 };

  get id() {
    return this.#student?.id ?? 0;
  }

  get givenNameOnly() {
    return this.#student?.givenName ?? '';
  }

  get username() {
    return this.#student?.username ?? '';
  }

  get givenName() {
    return this.studentForm.getSafe(x => x.givenName);
  }

  get familyName() {
    return this.studentForm.getSafe(x => x.familyName);
  }

  get gradeId() {
    return this.studentForm.getSafe(x => x.gradeId);
  }

  get regionId() {
    return this.studentForm.getSafe(x => x.regionId);
  }

  get soundRegionId() {
    return this.studentForm.getSafe(x => x.soundRegionId);
  }

  get treeId() {
    return this.studentForm.getSafe(x => x.treeId);
  }

  get school() {
    return this.studentForm.getSafe(x => x.school);
  }

  get dateOfBirth() {
    return this.studentForm.getSafe(x => x.dateOfBirth);
  }

  get gender() {
    return this.studentForm.getSafe(x => x.gender);
  }

  get ianaTimeZoneName() {
    return this.studentForm.getSafe(x => x.ianaTimeZoneName);
  }

  @Input({ required: true }) allowPasswordReset = false;

  @Input() studentForm!: FormGroupTypeSafe<Student>;

  @Input() set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      if (value) {
        this.#subscriptions.push(
          this.#studentService.getStudentInfoById(value)
            .subscribe(student => {
              this.student = student;
              this.#changeDetectorRef.markForCheck();
            }));
      }
    }
  }

  get studentId() {
    return this.#studentId;
  }

  @Input() set student(value: Student | undefined) {
    if (this.#student !== value) {
      this.#student = value;
      if (value) {
        this.studentForm.patchSafe({
          id: value.id,
          givenName: value.givenName,
          familyName: value.familyName,
          dateOfBirth: value.dateOfBirth,
          gender: value.gender,
          gradeId: value.gradeId,
          regionId: value.regionId,
          treeId: value.treeId ?? 0,
          soundRegionId: value.soundRegionId,
          school: value.school,
          ianaTimeZoneName: value.ianaTimeZoneName
        });
      }
    }
  }

  get student() {
    return this.#student;
  }

  @Output() readonly submitted = new EventEmitter<StudentUpdateStatus>();
  @Output() readonly cancelled = new EventEmitter();
  @Output() readonly resetPassword = new EventEmitter<Student>();

  constructor() {
    this.studentForm = StudentEditBaseComponent.buildDefaultControls(this.#fb);
  }

  /*eslint-disable @typescript-eslint/unbound-method */
  static buildDefaultControls(fb: FormBuilderTypeSafe): FormGroupTypeSafe<Student> {
    return fb.group<Student>({
      id: new FormControl<number>(0, Validators.required),
      givenName: new FormControl<string | null>(null, Validators.required),
      familyName: new FormControl<string | null>(null, Validators.required),
      dateOfBirth: new FormControl<DateStruct | null>(null),
      gender: new FormControl<Gender | null>(null, Validators.required),
      gradeId: new FormControl<number | null>(null, Validators.required),
      regionId: new FormControl<number>(RegionId.Australia, Validators.required),
      soundRegionId: new FormControl<number>(RegionId.Australia, Validators.required),
      treeId: new FormControl<number>(0, Validators.required),
      school: new FormControl<string | null>(null),
      ianaTimeZoneName: new FormControl<string | null>(null)
    });
  }
  /*eslint-enable @typescript-eslint/unbound-method */

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    this.#subscriptions.push(
      this.#studentService.updateExistingStudent(this.id, this.studentForm.value).subscribe(
        {
          next: value => {
            if (value === null) {
              this.submitted.emit(this.#createUpdateStatus());
            }
          },
          error: (error: unknown) => {
            this.studentForm.handleServerErrors(error);
            this.#changeDetectorRef.markForCheck();
          }
        }));
  }

  #createUpdateStatus(): StudentUpdateStatus | undefined {
    if (this.studentForm) {
      const givenName = this.givenName;
      const familyName = this.familyName;
      const regionId = this.regionId;
      const soundRegionId = this.soundRegionId;
      return {
        hasChanges: givenName.dirty || familyName.dirty || regionId.dirty,
        givenName: givenName.value,
        familyName: familyName.value,
        regionId: regionId.value,
        soundRegionId: soundRegionId.value
      };
    }
    return undefined;
  }
}
