<h2 class="pb-4 fw-light">Student Login</h2>
<kip-readonly-control id="username" label="UserName" value="username" />
@if (allowPasswordReset) {
<button class="btn btn-link p-0 align-self-start" type="button" (click)="resetPassword.emit(student)">Reset
  {{givenNameOnly}}'s Password</button>
}
<h2 class="py-4 fw-light">Student Details</h2>
<kip-form-group-wrapper [formGroup]="studentForm" [showCancel]="true" (cancelled)="cancelled.emit()"
  (submittedAndValidated)="submit()">
  <kip-form-control-text id="givenName" label="Given Name" [control]="givenName" />
  <kip-form-control-text id="familyName" label="Family Name" [control]="familyName" />
  <kip-form-control-gender id="gender" label="Gender" [control]="gender" />
  <kip-form-control-date id="dateOfBirth" label="Date of Birth" [control]="dateOfBirth" [maxDate]="maxDateOfBirth"
    [minDate]="minDateOfBirth" />
  <kip-form-control-grade id="gradeAtSchool" label="Grade at School" [control]="gradeId" />
  <kip-form-control-timezone id="Timezone" label="Timezone" [control]="ianaTimeZoneName" />
  <kip-form-control-region id="curriculumRegion" label="Curriculum Region" [control]="regionId" />
  <kip-form-control-region id="curriculumSoundRegion" label="Curriculum Sound Region" [control]="soundRegionId" />
  <kip-form-control-tree description="Default tree for centre" id="tree" label="Tree" [control]="treeId"
    [regionId]="regionId" />
  <kip-form-control-text id="school" label="School" [control]="school" />
  @if (student) {
  <kip-student-photo [studentId]="student.id" />
  }
</kip-form-group-wrapper>