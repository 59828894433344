import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';

import { AcceptActivity, EventsRequest, LessonPlan, NewDropInSessionLessonResponse } from '../models';

interface NewLessonPlanResponse {
  readonly lessonPlanId: number;
}

interface AcceptLessonPlanResponse {
  readonly isDIYCompatible: boolean;
  readonly hasAssessmentActivities: boolean;
}

@Injectable({ providedIn: 'root' })
export class LessonPlanService extends HttpService {

  readonly lessonPlanApiEndpoint = 'tutor/lesson-plan';

  getLessonPlan(lessonPlanId: number): Observable<LessonPlan> {
    return this.get<LessonPlan>(`tutor/lesson-plan/${lessonPlanId}`);
  }

  reGenerateLessonPlan(lessonPlanId: number): Observable<NewLessonPlanResponse> {
    return this.post<NewLessonPlanResponse>(`${this.lessonPlanApiEndpoint}/regenerate`, { lessonPlanId: lessonPlanId });
  }

  generateLessonPlan(enrolmentId: number): Observable<NewLessonPlanResponse> {
    return this.post<NewLessonPlanResponse>(this.lessonPlanApiEndpoint, { enrolmentId: enrolmentId });
  }

  acceptStudentDropInSessionRequest(sessionId: number, studentId: number): Observable<NewDropInSessionLessonResponse> {
    return this.get<NewDropInSessionLessonResponse>(`tutor/lesson-plan/generate-drop-in-lesson/${sessionId}/student/${studentId}`);
  }

  acceptLessonPlan(lessonPlanId: number, lessonPlan: LessonPlan, notifyChanges: boolean, eventsRequest: EventsRequest, copyingLessonPlan = false): Observable<AcceptLessonPlanResponse> {
    const activities: AcceptActivity[] = lessonPlan.activities.map(s => (
      {
        activityId: s.activityId,
        associationType: s.associationType,
        lessonActivityPlanId: copyingLessonPlan ? 0 : s.lessonActivityPlanId,
        previousLessonActivityPlanId: copyingLessonPlan ? null : s.previousLessonActivityPlanId,
        html: s.html,
        markdown: s.markdown
      }));

    // if no homework allowed, completely ignore anything that comes through
    const homework: AcceptActivity[] = lessonPlan.maximumAllowedHomeworkActivities === 0 ? [] :
      lessonPlan.homework.map(s => (
        {
          activityId: s.activityId,
          associationType: s.associationType,
          lessonActivityPlanId: copyingLessonPlan ? 0 : s.lessonActivityPlanId,
          previousLessonActivityPlanId: copyingLessonPlan ? null : s.previousLessonActivityPlanId,
          html: s.html,
          markdown: s.markdown
        }));

    return this.post<AcceptLessonPlanResponse>(`tutor/lesson-plan/${lessonPlanId}`,
      {
        activities: activities, homework: homework, notifyChanges: notifyChanges, eventsRequest: eventsRequest
      });
  }
}
