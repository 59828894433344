import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { RegionId } from 'questions-lib';

import { RegionFlagAeComponent } from './region-flag-ae.component';
import { RegionFlagAuComponent } from './region-flag-au.component';
import { RegionFlagGbComponent } from './region-flag-gb.component';
import { RegionFlagNzComponent } from './region-flag-nz.component';
import { RegionFlagUsComponent } from './region-flag-us.component';
import { RegionFlagZaComponent } from './region-flag-za.component';

@Component({
  selector: 'kip-region-flag',
  templateUrl: './region-flag.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionFlagComponent implements OnInit {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #regionId: RegionId = RegionId.Australia;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set regionId(value: RegionId) {
    if (this.#regionId !== value) {
      this.#regionId = value;
      this.#rebuildFlag();
    }
  }

  get regionId() {
    return this.#regionId;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('content', { read: ViewContainerRef, static: true }) content: ViewContainerRef | undefined;

  ngOnInit() {
    this.#rebuildFlag();
  }

  #rebuildFlag() {
    if (this.content) {
      this.content.clear();
      switch (this.#regionId) {
        case RegionId.Australia:
          this.content.createComponent(RegionFlagAuComponent);
          break;
        case RegionId.UnitedKingdom:
          this.content.createComponent(RegionFlagGbComponent);
          break;
        case RegionId.NewZealand:
          this.content.createComponent(RegionFlagNzComponent);
          break;
        case RegionId.SouthAfrica:
          this.content.createComponent(RegionFlagZaComponent);
          break;
        case RegionId.UnitedStates:
          this.content.createComponent(RegionFlagUsComponent);
          break;
        case RegionId.UnitedArabEmirates:
          this.content.createComponent(RegionFlagAeComponent);
          break;
      }
      this.#changeDetectorRef.markForCheck();
    }
  }

}
