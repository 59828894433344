/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from 'auth-lib';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { ServiceEnvironment } from 'service-lib';

import {
  ToolbarSvg,
  WhiteboardEvent,
  WhiteboardEventMessage,
  WhiteboardEventsMessage,
  WhiteboardEventType,
  WhiteboardGridTypeMessage,
  WhiteboardGuidKey,
  WhiteboardOpenMessage,
  WhiteboardToolbarSelection
} from './models';

interface WhiteboardPartialEvent {
  readonly eventId: string;
  readonly timestamp: Date;
  readonly lessonGuid: string;
  readonly activityGuid: string;
  readonly pageGuid: string;
  readonly data: WhiteboardEvent;
}

interface PrintData {
  readonly printing: boolean;
  readonly initialPage?: number;
}
export interface PrintElement {
  image: HTMLImageElement;
  canvas: HTMLImageElement;
  activityGuid: string;
}

@Injectable({
  providedIn: 'root'
})
export class WhiteboardService {

  readonly #router = inject(Router);
  readonly #authService = inject(AuthService);

  private readonly _clear$ = new Subject<WhiteboardGuidKey>();
  private readonly _load$ = new Subject<WhiteboardEventsMessage>();
  private readonly _unload$ = new Subject<void>();
  private readonly _loadComplete$ = new Subject<Date>();
  private readonly _printElementAdded = new Subject<Date>();
  private readonly _messages$ = new Subject<WhiteboardEventMessage>();
  private readonly _gridType$ = new Subject<WhiteboardGridTypeMessage>();
  private readonly _open$ = new Subject<WhiteboardOpenMessage>();
  private readonly _close$ = new Subject<WhiteboardGuidKey>();
  private readonly _destroy$ = new Subject<void>();
  private readonly _textBoxState$ = new Subject<boolean>();
  private readonly _toolbarOpenState$ = new BehaviorSubject<boolean>(false);
  private readonly _printData = new BehaviorSubject<PrintData>({ printing: false });
  private _initialPage = 0;
  private _printElements: PrintElement[] = [];
  private partialEvent: WhiteboardPartialEvent | undefined;
  private partialEventPath: string | undefined;

  isTextBoxSelected = false;
  isToolbarOpen = false;
  pagesLeftToPrint = 0;
  selection?: WhiteboardToolbarSelection;

  constructor() {
    window.addEventListener('beforeunload', () => {
      this.#sendPartialWhiteboardEvents();
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.#sendPartialWhiteboardEvents();
      }
    });

    this.#router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.#sendPartialWhiteboardEvents();
      }
    });

    this.destroy$.subscribe(() => this.#sendPartialWhiteboardEvents());
    this._textBoxState$.subscribe(state => this.isTextBoxSelected = state);
    this._toolbarOpenState$.subscribe(state => {
      this.isToolbarOpen = state;

      if (this.isToolbarOpen && this.selection?.tool.name === ToolbarSvg.Text && !this.isTextBoxSelected) {
        this.setSelectedTextbookState(this.selection);
      }
    });
  }

  get printElements() {
    return this._printElements;
  }

  get printing$() {
    return this._printData;
  }

  get printing() {
    return this._printData.value.printing;
  }

  get clear$(): Observable<WhiteboardGuidKey> {
    return this._clear$;
  }

  get load$(): Observable<WhiteboardEventsMessage> {
    return this._load$;
  }

  get printElementAdded(): Observable<Date> {
    return this._printElementAdded;
  }

  get loadComplete$(): Observable<Date> {
    return this._loadComplete$;
  }

  get messages$(): Observable<WhiteboardEventMessage> {
    return this._messages$;
  }

  get gridType$(): Observable<WhiteboardGridTypeMessage> {
    return this._gridType$;
  }

  get open$(): Observable<WhiteboardOpenMessage> {
    return this._open$;
  }

  get unload$(): Observable<void> {
    return this._unload$;
  }

  get close$(): Observable<WhiteboardGuidKey> {
    return this._close$;
  }

  get destroy$(): Observable<void> {
    return this._destroy$;
  }

  get textBoxState$(): Observable<boolean> {
    return this._textBoxState$;
  }

  get toolbarOpenState$(): Observable<boolean> {
    return this._toolbarOpenState$;
  }

  startPrinting(initialPage: number) {
    this._initialPage = initialPage;
    this._printData.next({ printing: true, initialPage: initialPage });
    this._printElements = [];
  }

  finishPrinting() {
    this._printData.next({ printing: false, initialPage: this._initialPage });
    this._printElements = [];
  }

  addPrintElement(element: PrintElement) {
    this._printElements.push(element);
    this._printElementAdded.next(new Date());
  }

  clear(clear: WhiteboardGuidKey) {
    this._clear$.next(clear);
  }

  load(events: WhiteboardEventsMessage) {
    this._load$.next(events);
  }

  unload() {
    this._unload$.next();
  }

  markLoadComplete() {
    this._loadComplete$.next(new Date());
  }

  applyMessage(event: WhiteboardEventMessage) {
    this._messages$.next(event);
  }

  open(open: WhiteboardOpenMessage) {
    this._open$.next(open);
  }

  close(close: WhiteboardGuidKey) {
    this._close$.next(close);
  }

  destroy() {
    this._destroy$.next();
  }

  applyGridType(event: WhiteboardGridTypeMessage) {
    this._gridType$.next(event);
  }

  storePartialWhiteboardEvent(eventPath: string, partialEvent: WhiteboardPartialEvent) {
    this.partialEvent = partialEvent;
    this.partialEventPath = eventPath;
  }

  clearPartialWhiteboardEvent() {
    this.partialEvent = undefined;
    this.partialEventPath = undefined;
  }

  isPartialEvent(event: WhiteboardEvent) {
    return event.type === WhiteboardEventType.PartiallyAdded || event.type === WhiteboardEventType.PartiallyUpdated;
  }

  onSelectTool(selection: WhiteboardToolbarSelection) {
    this.selection = selection;
    this.setSelectedTextbookState(selection);
  }

  onOpenToolbar() {
    this._toolbarOpenState$.next(true);
  }

  onCloseToolbar() {
    this.hideKeyboard();
    this._toolbarOpenState$.next(false);
  }

  hideKeyboard() {
    this._textBoxState$.next(false);
  }

  setSelectedTextbookState(selection: WhiteboardToolbarSelection) {
    this._textBoxState$.next(selection.tool.name === ToolbarSvg.Text && this.isToolbarOpen);
  }

  #sendPartialWhiteboardEvents() {
    this.#authService.getToken().pipe(take(1)).subscribe(token => {
      if (this.partialEvent) {
        const eventType = this.partialEvent.data.type === WhiteboardEventType.PartiallyAdded ?
          WhiteboardEventType.Added : WhiteboardEventType.Updated;
        const request = Object.assign({}, this.partialEvent, {
          data: Object.assign({}, this.partialEvent.data, { type: eventType }),
          token: token
        });
        const body = new Blob([JSON.stringify(request)], { type: 'application/json' });
        navigator.sendBeacon(`${ServiceEnvironment.value.api.endpoint}/whiteboard/events/${this.partialEventPath}/?beacon=true`, body);
      }
      this.partialEvent = undefined;
      this.partialEventPath = undefined;
    });
  }

}
