import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';

@Pipe({
  name: 'ends'
})
export class EndsPipe implements PipeTransform {

  transform(value: DateStruct): string {

    const today = moment().startOf('day');
    const date = moment().toMoment(value, '00:00:00');
    if (date.isBefore(today)) {
      return 'Ended On';
    }

    return 'Ends On';
  }
}
