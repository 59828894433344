import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Icons } from 'icon-lib';
import { Observable, of, Subscription } from 'rxjs';
import {
  daysOfWeek, FilterList, FilterValue, PageInfo, PageListSize, PageRequest, ProfileService, RouteParams,
  SessionScheduleFilter, SharedService, SortBy, SortColumnKey, toName
} from 'ui-common-lib';

import { StudentEnrolmentDetail, StudentSummary } from '../../../models';
import { StudentService } from '../../../services';

@Component({
  selector: 'kip-student-enrolment',
  templateUrl: './student-enrolment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentEnrolmentComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #route = inject(ActivatedRoute);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #sharedService = inject(SharedService);

  #canEdit = false;
  #subscriptions: Subscription[] = [];
  #studentId: number | undefined;
  #enrolmentDetail: StudentEnrolmentDetail | undefined;
  sortBy: SortBy | undefined;
  readonly sortColumnKey = SortColumnKey;
  readonly resultsPerPage = PageListSize.TwentyFive;
  readonly icons = Icons;

  currentLoad: Observable<PageInfo> = new Observable<PageInfo>();

  filter: SessionScheduleFilter = {
    centreId: null,
    weekday: null,
    tutorId: null
  };

  filterList: FilterList = {
    tutors: [],
    centres: [],
    daysOfWeek: daysOfWeek
  };

  get canEdit() {
    return this.#canEdit;
  }

  get currentEnrolments() {
    return this.#enrolmentDetail ? this.#enrolmentDetail.currentEnrolments : null;
  }

  get previousEnrolments() {
    return this.#enrolmentDetail ? this.#enrolmentDetail.previousEnrolments : null;
  }

  @Input({ required: true }) studentDetail: StudentSummary | undefined;

  ngOnInit() {
    this.#subscriptions.push(
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#sharedService.getCentres().subscribe(values => {
        this.filterList.centres = values;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#route.paramMap.subscribe(paramMap => {
        this.#studentId = +(paramMap.get(RouteParams.StudentId) ?? 0);
        if (this.#studentId) {
          const firstPage = 1;
          this.loadPage({
            pageNumber: firstPage,
            pageSize: this.resultsPerPage
          });
        }
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  loadPage(pageToFetch: PageRequest<SessionScheduleFilter>) {
    if (this.#studentId) {
      this.#subscriptions.push(
        this.#studentService.queryEnrolmentsByStudentId(this.#studentId, pageToFetch)
          .subscribe(enrolmentDetail => {
            this.#enrolmentDetail = enrolmentDetail;
            this.currentLoad = of(enrolmentDetail.previousEnrolments.pageInfo);
            this.#changeDetectorRef.markForCheck();
          }));
    }
  }

  updateFilter(value: Partial<SessionScheduleFilter>) {
    this.filter = { ... this.filter, ...value };
  }

  updateCentreFilter(value: number | null) {
    this.filter = { ... this.filter, centreId: value, tutorId: null };

    if (value) {
      this.#subscriptions.push(
        this.#sharedService.getTutors(value).subscribe(values => {
          this.filterList.tutors = values.map(t => {
            return { id: t.id, title: toName(t) } as FilterValue<number>;
          });
          this.#changeDetectorRef.markForCheck();
        }));
    } else {
      this.filterList.tutors = [];
    }
  }
}
