import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsLibModule } from 'forms-lib';
import { IconLibModule } from 'icon-lib';
import { MomentExtensionsModule } from 'moment-extensions-lib';
import { ColorPickerModule } from 'ngx-color-picker';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { ServiceLibModule } from 'service-lib';

import { AvatarComponent } from './avatar/avatar.component';
import { BasePageComponent } from './base-page/base-page.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BulkImportFileComponent } from './bulk-import-file/bulk-import-file.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { CardPagedComponent } from './card-paged/card-paged.component';
import { CollapsibleSectionComponent } from './collapsible-section/collapsible-section.component';
import { KipAwardsBadgeComponent } from './common-svg/kip-awards-badge.component';
import { KipPointsBadgeComponent } from './common-svg/kip-points-badge.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConsoleLogComponent } from './console-log/console-log.component';
import { ConsoleLogModalComponent } from './console-log/console-log-modal.component';
import { ConstructionComponent } from './construction/construction.component';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { CreditCardPopupComponent } from './credit-card/credit-card-popup/credit-card-popup.component';
import { DirectDebitComponent } from './direct-debit/direct-debit.component';
import { DiyLessonCardComponent } from './diy-lesson-card/diy-lesson-card.component';
import { DocumentCardComponent } from './document-card/document-card.component';
import { DocumentUploadComponent } from './document-uploader/document-upload.component';
import { DropFileDirective } from './drop-file/drop-file.directive';
import { DropInSessionAvailabilityComponent } from './drop-in-session-availability/drop-in-session-availability.component';
import { DropZoneDirective } from './dropzone/dropzone.directive';
import { EnrolmentSessionComponent } from './enrolment-session-picker/enrolment-session/enrolment-session.component';
import { EnrolmentSessionPickerComponent } from './enrolment-session-picker/enrolment-session-picker.component';
import { ExpandableSettingComponent } from './expandable-setting/expandable-setting.component';
import { FeatureGridComponent } from './feature/feature-grid.component';
import { FeatureItemComponent } from './feature/feature-item.component';
import { SideBarPipe } from './feature/side-bar.pipe';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { FormControlComponent } from './form-controls/form-control/form-control.component';
import { FormControlAccessKeysComponent } from './form-controls/form-control-access-keys/form-control-access-keys.component';
import { FormControlAdjustableHeaderComponent } from './form-controls/form-control-adjustable-header/form-control-adjustable-header.component';
import { FormControlAiModelComponent } from './form-controls/form-control-ai-model/form-control-ai-model.component';
import { FormControlBundleOfferComponent } from './form-controls/form-control-bundle-offer/form-control-bundle-offer.component';
import { FormControlCentreComponent } from './form-controls/form-control-centre/form-control-centre.component';
import { FormControlCentresComponent } from './form-controls/form-control-centres/form-control-centres.component';
import { FormControlCheckboxComponent } from './form-controls/form-control-checkbox/form-control-checkbox.component';
import { FormControlContactTypeComponent } from './form-controls/form-control-contact-type/form-control-contact-type.component';
import { FormControlCurrenciesComponent } from './form-controls/form-control-currencies/form-control-currencies.component';
import { FormControlCurrencyComponent } from './form-controls/form-control-currency/form-control-currency.component';
import { FormControlDateComponent } from './form-controls/form-control-date/form-control-date.component';
import { FormControlDayComponent } from './form-controls/form-control-day/form-control-day.component';
import { FormControlDeliveryMethodComponent } from './form-controls/form-control-delivery-method/form-control-delivery-mthod.component';
import { FormControlDeliveryMethodsComponent } from './form-controls/form-control-delivery-methods/form-control-delivery-methods.component';
import { FormControlDocumentTypeComponent } from './form-controls/form-control-document-type/form-control-document-type.component';
import { FormControlGenderComponent } from './form-controls/form-control-gender/form-control-gender.component';
import { FormControlGradeComponent } from './form-controls/form-control-grade/form-control-grade.component';
import { FormControlHiddenTextComponent } from './form-controls/form-control-hidden-text/form-control-hidden-text.component';
import { FormControlLongTextComponent } from './form-controls/form-control-long-text/form-control-long-text.component';
import { FormControlNumberComponent } from './form-controls/form-control-number/form-control-number.component';
import { FormControlOrganisationComponent } from './form-controls/form-control-organisation/form-control-organisation.component';
import { FormControlOverlaysComponent } from './form-controls/form-control-overlays/form-control-overlays.component';
import { FormControlRadioComponent } from './form-controls/form-control-radio/form-control-radio.component';
import { FormControlRegionComponent } from './form-controls/form-control-region/form-control-region.component';
import { FormControlRelationshipComponent } from './form-controls/form-control-relationship/form-control-relationship.component';
import { FormControlSelectComponent } from './form-controls/form-control-select/form-control-select.component';
import { FormControlSessionTypeComponent } from './form-controls/form-control-session-type/form-control-session-type.component';
import { FormControlSpellingSpeedComponent } from './form-controls/form-control-spelling-speed/form-control-spelling-speed.component';
import { FormControlThemeComponent } from './form-controls/form-control-spelling-theme/form-control-theme.component';
import { FormControlSubjectComponent } from './form-controls/form-control-subject/form-control-subject.component';
import { FormControlSubjectsComponent } from './form-controls/form-control-subjects/form-control-subjects.component';
import { FormControlTermsComponent } from './form-controls/form-control-terms/form-control-terms.component';
import { FormControlTextComponent } from './form-controls/form-control-text/form-control-text.component';
import { FormControlTimeComponent } from './form-controls/form-control-time/form-control-time.component';
import { FormControlTimeZoneComponent } from './form-controls/form-control-timezone/form-control-timezone.component';
import { FormControlTreeComponent } from './form-controls/form-control-tree/form-control-tree.component';
import { FormControlTutorComponent } from './form-controls/form-control-tutor/form-control-tutor.component';
import { FormControlVideoRecordOptionComponent } from './form-controls/form-control-video-record-option/form-control-video-record-option.component';
import { FormGroupWrapperComponent } from './form-group-wrapper/form-group-wrapper.component';
import { NgbDateFormatter } from './helpers/ngb-date-formatter';
import { HtmlModalComponent } from './html-modal/html-modal.component';
import { FileUploaderComponent } from './image-uploader/file-uploader.component';
import { IntTelInputComponent } from './int-tel-input/int-tel-input.component';
import { InCenterOnScreenComponent } from './lesson-graphic/in-center-on-screen.component';
import { InCenterOnScreenHybridComponent } from './lesson-graphic/in-center-on-screen-hybrid.component';
import { LessonGraphicAssessmentComponent } from './lesson-graphic/lesson-graphic-assessment.component';
import { LessonGraphicHybridComponent } from './lesson-graphic/lesson-graphic-hybrid.component';
import { LessonGraphicInCenterComponent } from './lesson-graphic/lesson-graphic-in-center.component';
import { LessonGraphicOnScreenComponent } from './lesson-graphic/lesson-graphic-on-screen.component';
import { PhotoInCenterOnScreenComponent } from './lesson-graphic/photo-in-center-on-screen.component';
import { LoaderComponent } from './loader/loader.component';
import { LoadingStateComponent } from './loading-state/loading-state.component';
import { LogoComponent } from './logo/kip-logo.component';
import { ModelSelectComponent } from './model-controls/model-select/model-select.component';
import {
  MontyBodyComponent,
  MontyComponent,
  MontyConstructionComponent,
  MontyEmptyComponent,
  MontyErrorComponent,
  MontyNewComponent,
  MontyOnlineBodyComponent,
  MontySlowRequestComponent,
  MontySuccessBodyComponent,
  MontySuccessComponent} from './monty';
import { MontyHeadComponent } from './monty/monty-head/monty-head.component';
import { MontyLoadingComponent } from './monty/monty-loading/monty-loading.component';
import { MyDayLessonDashboardComponent } from './my-day-lesson-dashboard/my-day-lesson-dashboard.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { OkDialogComponent } from './ok-dialog/ok-dialog.component';
import { OnOffButtonComponent } from './on-off-button/on-off-button.component';
import { OverlayComponent } from './overlay/overlay.component';
import { PagedDateFilterComponent } from './paged-date-filter/paged-date-filter.component';
import { PagedFilterComponent } from './paged-filter/paged-filter.component';
import { PagedListComponent } from './paged-list/paged-list.component';
import { PagedTableComponent } from './paged-table/paged-table.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordFormPartialComponent } from './password-form-partial/password-form-partial.component';
import { AccessKeysMultiplePickerComponent } from './pickers/access-keys-multiple-picker/access-keys-multiple-picker.component';
import { AddressPickerComponent } from './pickers/address-picker/address-picker.component';
import { AddressPickerOptionalComponent } from './pickers/address-picker-optional/address-picker-optional.component';
import { AdjustableHeaderPickerComponent } from './pickers/adjustable-header-picker/adjustable-header-picker.component';
import { AiModelPickerComponent } from './pickers/ai-model-picker/ai-model-picker.component';
import { BaseMultiplePickerComponent } from './pickers/base-multiple-picker/base-multiple-picker.component';
import { BillingPickerComponent } from './pickers/billing-picker/billing-picker.component';
import { BundleOfferTypePickerComponent } from './pickers/bundle-offer-type-picker/bundle-offer-type-picker.component';
import { CentreMultiplePickerComponent } from './pickers/centre-multiple-picker/centre-multiple-picker.component';
import { CentrePickerComponent } from './pickers/centre-picker/centre-picker.component';
import { ColorPickerComponent } from './pickers/color-picker/color-picker.component';
import { CurrencyMultiplePickerComponent } from './pickers/currency-multiple-picker/currency-multiple-picker.component';
import { CurrencyPickerComponent } from './pickers/currency-picker/currency-picker.component';
import { DatePickerComponent } from './pickers/date-picker/date-picker.component';
import { DateRangePickerComponent } from './pickers/date-range-picker/date-range-picker.component';
import { DayPickerComponent, MultiDayPickerComponent } from './pickers/day-picker';
import {
  DeliveryMethodMultiplePickerComponent
} from './pickers/delivery-method-multiple-picker/delivery-method-multiple-picker.component';
import {
  DeliveryMethodSinglePickerComponent
} from './pickers/delivery-method-single-picker/delivery-method-single-picker.component';
import { DocumentTypePickerComponent } from './pickers/document-type-picker/document-type-picker.component';
import { GenderPickerComponent } from './pickers/gender-picker/gender-picker.component';
import { GenericPickerComponent } from './pickers/generic-picker/generic-picker.component';
import { GradePickerComponent } from './pickers/grade-picker/grade-picker.component';
import { OrganisationPickerComponent } from './pickers/organisation-picker/organisation-picker.component';
import { RankingPickerComponent } from './pickers/ranking-picker/ranking-picker.component';
import { RegionPickerComponent } from './pickers/region-picker/region-picker.component';
import { RelationshipPickerComponent } from './pickers/relationship-picker/relationship-picker.component';
import { SchoolRoleMultiplePickerComponent } from './pickers/school-role-multiple-picker/school-role-multiple-picker.component';
import { SessionTypeSinglePickerComponent } from './pickers/session-type-single-picker/session-type-single-picker.component';
import { SoundSetPickerComponent } from './pickers/sound-set-picker/sound-set-picker.component';
import { SubjectMultiplePickerComponent } from './pickers/subject-multiple-picker/subject-multiple-picker.component';
import { SubjectPickerComponent } from './pickers/subject-picker/subject-picker.component';
import { TermsAndConditionsPickerComponent } from './pickers/terms-and-conditions-picker/terms-and-conditions-picker.component';
import { TimePickerComponent } from './pickers/time-picker/time-picker.component';
import { TimeZonePickerComponent } from './pickers/timezone-picker/timezone-picker.component';
import { SimpleTreePickerComponent } from './pickers/tree-picker/tree-picker.component';
import { TutorPickerComponent } from './pickers/tutor-picker/tutor-picker.component';
import { VideoRecordOptionPickerComponent } from './pickers/video-record-option-picker/video-record-option-picker.component';
import { DeliveryMethodIconPipe, EnrolmentCategoryPipe, FilterTitlePipe, GenderIconPipe, GenderPipe, PaymentMethodPipe } from './pipes';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { QrCodeUploaderComponent } from './qr-code-uploader/qr-code-uploader.component';
import { RankingComponent } from './ranking/ranking.component';
import { ReadonlyControlComponent } from './readonly-control/readonly-control.component';
import { RegionFlagComponent } from './region-flag/region-flag.component';
import { RegionFlagAuComponent } from './region-flag/region-flag-au.component';
import { RegionFlagGbComponent } from './region-flag/region-flag-gb.component';
import { RegionFlagNzComponent } from './region-flag/region-flag-nz.component';
import { RegionFlagUsComponent } from './region-flag/region-flag-us.component';
import { RegionFlagZaComponent } from './region-flag/region-flag-za.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { SessionPickerComponent } from './session-picker/session-picker.component';
import { SessionPickerGridComponent } from './session-picker/session-picker-grid/session-picker-grid.component';
import { SessionPickerListComponent } from './session-picker/session-picker-list/session-picker-list.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SuccessRequestComponent } from './success-request/success-request.component';
import { SupportedBrowserComponent } from './supported-browser/supported-browser.component';
import { TabsComponent } from './tabs/tabs.component';
import { ToggleComponent } from './toggle/toggle.component';
import {
  TutorDropInSessionProficiencySettingsComponent
} from './tutor-drop-in-session-settings/tutor-drop-in-session-proficiency-settings.component';
import {
  TutorDropInSessionSettingsComponent
} from './tutor-drop-in-session-settings/tutor-drop-in-session-settings.component';
import {
  TutorDropInSessionSubjectSettingsComponent
} from './tutor-drop-in-session-settings/tutor-drop-in-session-subject-settings.component';
import { TutorPasswordComponent } from './tutor-password/tutor-password.component';
import { TutorPhotoComponent } from './tutor-photo/tutor-photo.component';
import { UpdateComponent } from './update/update.component';
import { UploadedFileListComponent } from './uploaded-image-list/uploaded-file-list.component';
import { VideoComponent } from './video/video.component';

@NgModule({
  declarations: [
    AccessKeysMultiplePickerComponent,
    AddressPickerComponent,
    AddressPickerOptionalComponent,
    AdjustableHeaderPickerComponent,
    AiModelPickerComponent,
    AvatarComponent,
    BaseMultiplePickerComponent,
    BasePageComponent,
    BillingPickerComponent,
    BreadcrumbsComponent,
    BulkImportFileComponent,
    BundleOfferTypePickerComponent,
    ButtonsComponent,
    CardPagedComponent,
    CentreMultiplePickerComponent,
    CentrePickerComponent,
    CollapsibleSectionComponent,
    ColorPickerComponent,
    ConfirmDialogComponent,
    ConsoleLogComponent,
    ConsoleLogModalComponent,
    ConstructionComponent,
    ContactCardComponent,
    CreditCardComponent,
    CreditCardPopupComponent,
    CurrencyMultiplePickerComponent,
    CurrencyPickerComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DayPickerComponent,
    DeliveryMethodIconPipe,
    DeliveryMethodMultiplePickerComponent,
    DeliveryMethodSinglePickerComponent,
    DirectDebitComponent,
    DiyLessonCardComponent,
    DocumentCardComponent,
    DocumentTypePickerComponent,
    DocumentUploadComponent,
    DropFileDirective,
    DropInSessionAvailabilityComponent,
    DropZoneDirective,
    EnrolmentCategoryPipe,
    EnrolmentSessionComponent,
    EnrolmentSessionPickerComponent,
    ExpandableSettingComponent,
    FeatureGridComponent,
    FeatureItemComponent,
    FileUploaderComponent,
    FilterButtonComponent,
    FilterTitlePipe,
    FormControlAccessKeysComponent,
    FormControlAdjustableHeaderComponent,
    FormControlAiModelComponent,
    FormControlBundleOfferComponent,
    FormControlCentreComponent,
    FormControlCentresComponent,
    FormControlCheckboxComponent,
    FormControlComponent,
    FormControlContactTypeComponent,
    FormControlCurrenciesComponent,
    FormControlCurrencyComponent,
    FormControlDateComponent,
    FormControlDayComponent,
    FormControlDeliveryMethodComponent,
    FormControlDeliveryMethodsComponent,
    FormControlDocumentTypeComponent,
    FormControlGenderComponent,
    FormControlGradeComponent,
    FormControlHiddenTextComponent,
    FormControlLongTextComponent,
    FormControlNumberComponent,
    FormControlOrganisationComponent,
    FormControlOverlaysComponent,
    FormControlRadioComponent,
    FormControlRegionComponent,
    FormControlRelationshipComponent,
    FormControlSelectComponent,
    FormControlSessionTypeComponent,
    FormControlSpellingSpeedComponent,
    FormControlSubjectComponent,
    FormControlSubjectsComponent,
    FormControlTermsComponent,
    FormControlTextComponent,
    FormControlThemeComponent,
    FormControlTimeComponent,
    FormControlTimeZoneComponent,
    FormControlTreeComponent,
    FormControlTutorComponent,
    FormControlVideoRecordOptionComponent,
    FormGroupWrapperComponent,
    GenderIconPipe,
    GenderPickerComponent,
    GenderPipe,
    GenericPickerComponent,
    GradePickerComponent,
    HtmlModalComponent,
    InCenterOnScreenComponent,
    InCenterOnScreenHybridComponent,
    IntTelInputComponent,
    KipAwardsBadgeComponent,
    KipPointsBadgeComponent,
    LessonGraphicAssessmentComponent,
    LessonGraphicHybridComponent,
    LessonGraphicInCenterComponent,
    LessonGraphicOnScreenComponent,
    LoaderComponent,
    LoadingStateComponent,
    LogoComponent,
    ModelSelectComponent,
    MontyBodyComponent,
    MontyComponent,
    MontyConstructionComponent,
    MontyEmptyComponent,
    MontyErrorComponent,
    MontyHeadComponent,
    MontyLoadingComponent,
    MontyNewComponent,
    MontyOnlineBodyComponent,
    MontySlowRequestComponent,
    MontySuccessBodyComponent,
    MontySuccessComponent,
    MultiDayPickerComponent,
    MyDayLessonDashboardComponent,
    NoResultsComponent,
    OkDialogComponent,
    OnOffButtonComponent,
    OrganisationPickerComponent,
    OverlayComponent,
    PagedDateFilterComponent,
    PagedFilterComponent,
    PagedListComponent,
    PagedTableComponent,
    PasswordChangeComponent,
    PasswordFormPartialComponent,
    PaymentMethodPipe,
    PhotoInCenterOnScreenComponent,
    ProgressIndicatorComponent,
    QrCodeComponent,
    QrCodeUploaderComponent,
    RankingComponent,
    RankingPickerComponent,
    ReadonlyControlComponent,
    RegionFlagAuComponent,
    RegionFlagComponent,
    RegionFlagGbComponent,
    RegionFlagNzComponent,
    RegionFlagUsComponent,
    RegionFlagZaComponent,
    RegionPickerComponent,
    RelationshipPickerComponent,
    SchoolRoleMultiplePickerComponent,
    SendNotificationComponent,
    SessionPickerComponent,
    SessionPickerGridComponent,
    SessionPickerListComponent,
    SessionTypeSinglePickerComponent,
    SideBarPipe,
    SimpleTreePickerComponent,
    SoundSetPickerComponent,
    SpinnerComponent,
    SubjectMultiplePickerComponent,
    SubjectPickerComponent,
    SuccessRequestComponent,
    SupportedBrowserComponent,
    TabsComponent,
    TermsAndConditionsPickerComponent,
    TimePickerComponent,
    TimeZonePickerComponent,
    ToggleComponent,
    TutorDropInSessionProficiencySettingsComponent,
    TutorDropInSessionSettingsComponent,
    TutorDropInSessionSubjectSettingsComponent,
    TutorPasswordComponent,
    TutorPhotoComponent,
    TutorPickerComponent,
    UpdateComponent,
    UploadedFileListComponent,
    VideoComponent,
    VideoRecordOptionPickerComponent
  ],
  imports: [
    ColorPickerModule,
    CommonModule,
    FormsLibModule,
    FormsModule,
    IconLibModule,
    MomentExtensionsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    ServiceLibModule
  ],
  exports: [
    AccessKeysMultiplePickerComponent,
    AddressPickerComponent,
    AddressPickerOptionalComponent,
    AdjustableHeaderPickerComponent,
    AiModelPickerComponent,
    AvatarComponent,
    BaseMultiplePickerComponent,
    BasePageComponent,
    BillingPickerComponent,
    BreadcrumbsComponent,
    BulkImportFileComponent,
    BundleOfferTypePickerComponent,
    ButtonsComponent,
    CardPagedComponent,
    CentreMultiplePickerComponent,
    CentrePickerComponent,
    CollapsibleSectionComponent,
    ColorPickerComponent,
    ConfirmDialogComponent,
    ConsoleLogComponent,
    ConsoleLogModalComponent,
    ConstructionComponent,
    ContactCardComponent,
    CreditCardComponent,
    CreditCardPopupComponent,
    CurrencyMultiplePickerComponent,
    CurrencyPickerComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DayPickerComponent,
    DeliveryMethodIconPipe,
    DeliveryMethodMultiplePickerComponent,
    DeliveryMethodSinglePickerComponent,
    DirectDebitComponent,
    DiyLessonCardComponent,
    DocumentCardComponent,
    DocumentTypePickerComponent,
    DocumentUploadComponent,
    DragDropModule,
    DropFileDirective,
    DropInSessionAvailabilityComponent,
    EnrolmentCategoryPipe,
    EnrolmentSessionComponent,
    EnrolmentSessionPickerComponent,
    ExpandableSettingComponent,
    FeatureGridComponent,
    FeatureItemComponent,
    FileUploaderComponent,
    FilterButtonComponent,
    FilterTitlePipe,
    FormControlAccessKeysComponent,
    FormControlAdjustableHeaderComponent,
    FormControlAiModelComponent,
    FormControlBundleOfferComponent,
    FormControlCentreComponent,
    FormControlCentresComponent,
    FormControlCheckboxComponent,
    FormControlComponent,
    FormControlContactTypeComponent,
    FormControlCurrenciesComponent,
    FormControlCurrencyComponent,
    FormControlDateComponent,
    FormControlDayComponent,
    FormControlDeliveryMethodComponent,
    FormControlDeliveryMethodsComponent,
    FormControlDocumentTypeComponent,
    FormControlGenderComponent,
    FormControlGradeComponent,
    FormControlHiddenTextComponent,
    FormControlLongTextComponent,
    FormControlNumberComponent,
    FormControlOrganisationComponent,
    FormControlOverlaysComponent,
    FormControlRadioComponent,
    FormControlRegionComponent,
    FormControlRelationshipComponent,
    FormControlSelectComponent,
    FormControlSessionTypeComponent,
    FormControlSpellingSpeedComponent,
    FormControlSubjectComponent,
    FormControlSubjectsComponent,
    FormControlTermsComponent,
    FormControlTextComponent,
    FormControlThemeComponent,
    FormControlTimeComponent,
    FormControlTimeZoneComponent,
    FormControlTreeComponent,
    FormControlTutorComponent,
    FormControlVideoRecordOptionComponent,
    FormGroupWrapperComponent,
    GenderIconPipe,
    GenderPickerComponent,
    GenderPipe,
    GenericPickerComponent,
    GradePickerComponent,
    HtmlModalComponent,
    InCenterOnScreenComponent,
    InCenterOnScreenHybridComponent,
    IntTelInputComponent,
    KipAwardsBadgeComponent,
    KipPointsBadgeComponent,
    LessonGraphicAssessmentComponent,
    LessonGraphicHybridComponent,
    LessonGraphicInCenterComponent,
    LessonGraphicOnScreenComponent,
    LoaderComponent,
    LoadingStateComponent,
    LogoComponent,
    ModelSelectComponent,
    MontyBodyComponent,
    MontyComponent,
    MontyConstructionComponent,
    MontyEmptyComponent,
    MontyErrorComponent,
    MontyHeadComponent,
    MontyLoadingComponent,
    MontyNewComponent,
    MontyOnlineBodyComponent,
    MontySlowRequestComponent,
    MontySuccessBodyComponent,
    MontySuccessComponent,
    MultiDayPickerComponent,
    MyDayLessonDashboardComponent,
    NoResultsComponent,
    OkDialogComponent,
    OnOffButtonComponent,
    OrganisationPickerComponent,
    OverlayComponent,
    PagedDateFilterComponent,
    PagedFilterComponent,
    PagedListComponent,
    PagedTableComponent,
    PasswordChangeComponent,
    PasswordFormPartialComponent,
    PaymentMethodPipe,
    PhotoInCenterOnScreenComponent,
    ProgressIndicatorComponent,
    QrCodeComponent,
    QrCodeUploaderComponent,
    RankingComponent,
    RankingPickerComponent,
    ReadonlyControlComponent,
    RegionFlagAuComponent,
    RegionFlagComponent,
    RegionFlagGbComponent,
    RegionFlagNzComponent,
    RegionFlagUsComponent,
    RegionFlagZaComponent,
    RegionPickerComponent,
    RelationshipPickerComponent,
    SchoolRoleMultiplePickerComponent,
    SendNotificationComponent,
    SessionPickerComponent,
    SessionPickerGridComponent,
    SessionPickerListComponent,
    SessionTypeSinglePickerComponent,
    SideBarPipe,
    SimpleTreePickerComponent,
    SoundSetPickerComponent,
    SpinnerComponent,
    SubjectMultiplePickerComponent,
    SubjectPickerComponent,
    SuccessRequestComponent,
    SupportedBrowserComponent,
    TabsComponent,
    TermsAndConditionsPickerComponent,
    TimePickerComponent,
    TimeZonePickerComponent,
    ToggleComponent,
    TutorDropInSessionProficiencySettingsComponent,
    TutorDropInSessionSettingsComponent,
    TutorDropInSessionSubjectSettingsComponent,
    TutorPasswordComponent,
    TutorPhotoComponent,
    TutorPickerComponent,
    UpdateComponent,
    UploadedFileListComponent,
    VideoComponent,
    VideoRecordOptionPickerComponent
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFormatter }
  ]
})
export class UiCommonLibModule { }
