import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import * as moment from 'moment';

import { DashboardLesson, DashboardLessonAction, DashboardLessonAndHomework } from '../models';

@Component({
  selector: 'kip-my-day-lesson-dashboard',
  templateUrl: './my-day-lesson-dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyDayLessonDashboardComponent {

  readonly icons = Icons;

  get lesson() {
    return this.lessonAndHomework?.lesson;
  }

  get homework() {
    return this.lessonAndHomework?.homework;
  }

  get notPlanned() {
    // diy lessons can be considered planned always
    if (this.lessonAndHomework?.lesson) {
      return !this.lessonAndHomework.lesson.isAssessment && !this.lessonAndHomework.lesson.isDropIn && (this.lessonAndHomework.lesson.lessonPlanId === 0 || !this.lessonAndHomework.lesson.accepted);
    }

    return false;
  }

  get isPlanned() {
    return !this.notPlanned;
  }

  get newCalendar() {
    return !!this.useNewCalendar;
  }

  @Input({ required: true }) lessonAndHomework: DashboardLessonAndHomework | undefined;
  @Input() isHomework: boolean | undefined;
  @Input() displayOpenButtons = true;
  @Input() additionalActions: DashboardLessonAction[] | undefined;
  @Input() useNewCalendar: boolean | undefined;
  @Output() readonly openLesson = new EventEmitter();
  @Output() readonly openHomework = new EventEmitter();

  isUpcoming(lesson: DashboardLesson) {
    const now = moment();
    const lessonDate = moment(lesson.dateTime.dateTimeOffset);
    // if the date is different, it is an upcoming lesson
    return lessonDate.year() !== now.year() || lessonDate.month() !== now.month() || lessonDate.date() !== now.date();
  }
}
