<nav class="kip-navbar">
  <div
    class="kip-navbar-container kip-xl-container d-flex flex-row justify-content-between align-items-center mx-auto py-2 px-3">
    @if (isTutor) {
    <div ngbDropdown placement="bottom-left">
      <div id="menu" ngbDropdownToggle>
        <fa-icon class="kip-navigator text-center me-2" [icon]="icons.menu" />
      </div>
      <div aria-labelledby="menu"
        class="kip-dropdown__menu kip-dropdown__menu--top-left kip-dropdown__menu--top-left-sm ms-n2" ngbDropdownMenu>
        <button class="btn kip-dropdown__entry" type="button" (click)="dashboard()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.home.solid" />My Day
        </button>
        <button class="btn kip-dropdown__entry" type="button" (click)="planning()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.date" />Plan
        </button>
        @if (isDropInEnabled | async) {
        <button class="btn kip-dropdown__entry" type="button" (click)="dropIn()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.dropIn.icon" />Drop In
        </button>
        }
        <!--         <button type="button" class="btn kip-dropdown__entry" (click)="print()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.print.solid" />Print
        </button> -->
        <button class="btn kip-dropdown__entry" type="button" (click)="mark()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.mark" />Mark
        </button>
        <button class="btn kip-dropdown__entry" type="button" (click)="activities()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.activityType.manual" />Custom Activities
        </button>
        <button class="btn kip-dropdown__entry" type="button" (click)="history()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.history" />History
        </button>
        <button class="btn kip-dropdown__entry" type="button" (click)="goals()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.goals.regular" />Goals
        </button>
        @if (isCentreManager || isSuperUser)
        {
        <button class="btn kip-dropdown__entry" type="button" (click)="videos()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.video" />Videos
        </button>
        }
        <button class="btn kip-dropdown__entry" type="button" (click)="explore()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.explore" />Explore
        </button>
        @if (showSentry) {
        <button class="btn kip-dropdown__entry" type="button" (click)="tree()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.explore" />Trees
        </button>
        }
        @if (profile?.newAvatar)
        {
        <button class="btn kip-dropdown__entry" type="button" (click)="readyPlayerMeInteract()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.explore" />Avatar Interact Demo
        </button>
        }
        <button class="btn kip-dropdown__entry" type="button" (click)="help()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.helpGuide" />Help Guide
        </button>
        <button class="btn kip-dropdown__entry" type="button" (click)="initialPlan()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.initialLessonPlanTest" />Initial Plan Test
        </button>
        <button class="btn kip-dropdown__entry" type="button" (click)="next()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.initialLessonPlanTest" />Next Lesson Test
        </button>
        <button class="btn kip-dropdown__entry" type="button" (click)="diy()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.diyRule" />DIY Test
        </button>
        @if (showIcons) {
        <button class="btn kip-dropdown__entry" type="button" (click)="iconsList()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.explore" />Icons
        </button>
        }
        @if (showSentry) {
        <button class="btn kip-dropdown__entry" type="button" (click)="raiseSentryError()">
          <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.bug" />Simulate Sentry Error
        </button>
        }
      </div>
    </div>
    }
    <div class="kip-logo-container flex-fill me-auto" [class.cursor-pointer]="!isHome" (click)="!isHome && dashboard()">
      <kip-logo [whiteLabel]="whiteLabel" />
    </div>

    <div class="d-flex flex-row">
      <!-- No notifications at this stage - but we'll add these in at a later date -->
      <!-- <div class="kip-notifications d-none d-sm-inline-block position-relative px-3">
    <div class="kip-has-notifications">
      <fa-icon [icon]="notificationIcon" />
    </div>
  </div> -->
      <div ngbDropdown placement="bottom-right">
        @if (profile) {
        <div class="kip-user ps-3" id="userMenu" ngbDropdownToggle>
          <span class="kip-profile d-none d-sm-inline-block pe-2">{{ profile.name | name }}</span>
          <kip-avatar [myAvatar]="true" [name]="profile.name" />
        </div>
        }
        <div aria-labelledby="userMenu" class="kip-dropdown__menu kip-dropdown__menu--top-right ms-2 mt-3"
          ngbDropdownMenu style="min-width: 335px;">
          @if (isStudent) {
          <kip-theme-switcher />
          }

          @if (isStudent) {
          <div class="overlay-body" [style.max-height]="colorPicker.isSimpleMode ? '172px' : '335px'">
            <button class="btn kip-dropdown__entry" type="button" [class.border-bottom-0]="overlays?.enabled"
              (click)="onToggleOverlays()">
              <fa-icon class="text-primary kip-nav-toggle-icon m-0 pe-2" size="lg"
                [icon]="overlays?.enabled ? icons.toggle.on : icons.toggle.off" />Overlays
            </button>
            <kip-color-picker #colorPicker [class.border-bottom]="overlays?.enabled" [color]="overlays?.color"
              [hidden]="!overlays?.enabled" (changeColor)="onChangeOverlaysColor($event)" />
          </div>
          }

          @if (isStudent && !isHome) {
          <button class="btn kip-dropdown__entry" type="button" (click)="dashboard()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.home.solid" />My Day
          </button>
          }
          <button class="btn kip-dropdown__entry" type="button" (click)="settings()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.settings" />Settings
          </button>
          @if (installAvailable) {
          <button class="btn kip-dropdown__entry" type="button" (click)="install()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.download" />Install App
          </button>
          }
          @if (showReportIssueLink) {
          <a class="btn kip-dropdown__entry" target="_blank" type="button" [href]="franchiseePortalUrl">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.reportJira.regular" />Report Issues
          </a>
          }
          @if (!isStudent) {
          <button class="btn kip-dropdown__entry" type="button" (click)="changes()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.changeLog" />Change Log
          </button>
          }
          <button class="btn kip-dropdown__entry" type="button" (click)="avatar()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.avatar" />Avatar
          </button>
          @if (isStudent) {
          <button class="btn kip-dropdown__entry" type="button" (click)="studentAwards()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.points.certificates" />Awards
          </button>
          }
          @if (isStudent && showLoginCreation) {
          <button class="btn kip-dropdown__entry" type="button" (click)="loginCreationOpen = true">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.addStudent" />Add Parent
          </button>
          }
          @if ((isCentreManager || isSuperUser) && !isActing) {
          <button class="btn kip-dropdown__entry" type="button" (click)="switch.open()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.act" />Start Acting
          </button>
          }
          @if (isActing) {
          <button class="btn kip-dropdown__entry" type="button" (click)="stopActing()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.act" />Stop Acting
          </button>
          }
          @if (helpNodeName && isTutor) {
          <button class="btn kip-dropdown__entry" type="button" (click)="uId = helpNodeName">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.helpGuide" />Show Help
          </button>
          }
          <button class="btn kip-dropdown__entry" type="button" (click)="logout()">
            <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.signOut" />Logout
          </button>
        </div>
      </div>
    </div>
  </div>
  <kip-switch-user #switch />
  <kip-account-contact-login-modal [userId]="userId" [(isOpen)]="loginCreationOpen"
    (loginCreated)="loginCreated($event)" />
  @if (uId) {
  <kip-help-modal [(uId)]="uId" />
  }
</nav>