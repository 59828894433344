<kip-form-control [class]="class()" [control]="control()" [errorMessage]="errorMessage()" [label]="label()"
  [range]="range()">
  <label [class]="labelClass()??''" [for]="id()">{{ label() }}
    @if (labelExtra(); as labelExtra)
    {
    <span class="ms-2 small text-uppercase">{{ labelExtra }}</span>
    }
  </label>
  <input class="form-control" type="number" [formControl]="control()" [id]="id()" [max]="max()" [min]="min()"
    [readonly]="readonly()">
</kip-form-control>